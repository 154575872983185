@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";

.image-and-text {
  margin-top: -30px;

  h2 {
    display: block;
  }

  p {
    line-height: 24px;
    color: $text_gray;
    text-align: justify;
  }
}

@include up($tablet) {
  .about-main-container {
    margin-top: 30px;
  }

  .image-and-text {
    display: flex;

    h2 {
      display: none;
    }
  }

  .about-us-content {
    margin-left: 30px;
  }
}
