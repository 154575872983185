@import "../../styles/breakpoints.scss";

.menu {
  display: flex;
  margin-left: 20px;
  button {
    border: 0;
    background: inherit;
  }
}

@include up($tablet) {
  .menu {
    display: none;
  }
}
