@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.subcategory-body {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  width: 100%;
  visibility: hidden;
  background: $background_linear_green_secondary;
  border: 1px solid $subcategories-menu-border;
}

.subcategory-body-active {
  visibility: initial;
  transform: none;
  overflow-y: auto;
}

.title {
  display: flex;
}

.rotate-180 {
  transform: rotate(180deg);
}

@include up($tablet) {
  .subcategory-body {
    display: none;
    left: 100%;
    bottom: initial;
    transform: none;
    visibility: initial;
    min-width: 250px;
    box-shadow: 4px 4px 5px rgba($black, 0.25);
  }

  .title {
    display: none;
  }
}
