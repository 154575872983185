@use "../../styles/colors";
@import "../../styles/globals.scss";

.header {
  background: colors.$primary_gray;
  padding: 0 10px;
  width: 100%;
  height: 22px;
  position: relative;
  display: flex;
  justify-content: space-between;
  text-decoration: inherit;
  align-items: center;

  .header-text {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
  }

  .header-icon {
    align-items: center;
    height: 12px;
    width: 12px;
  }

  .header-left {
    color: colors.$white;
    display: flex;
    flex-direction: row;
    gap: 2px;
    margin: 2px;
    align-items: center;
  }

  .header-right {
    color: colors.$white;
    gap: 2px;
    display: flex;
    flex-direction: row;
    margin: 2px;
    align-items: center;

    .header-element {
      gap: 2px;
      display: flex;
      flex-direction: row;
      margin: 3px;
    }

    .header-phone {
      margin-right: 5px;

      a {
        text-decoration: none;
        color: colors.$white;

        &:hover {
          color: colors.$text_green;
        }

        &:active {
          color: colors.$text_green;
        }
      }
    }

    .header-email {
      a {
        text-decoration: none;
        color: colors.$white;

        &:hover {
          color: colors.$text_green;
        }

        &:active {
          color: colors.$text_green;
        }
      }
    }
  }
}

@include up($tablet) {
  .header {
    padding: 0 50px;

    .header-text {
      font-size: 14px;
    }

    .header-icon {
      height: 14px;
      width: 14px;
    }
  }
}

@include up($desktop-s) {
  .header {
    padding: 0 150px;

    .header-text {
      font-size: 16px;
    }

    .header-icon {
      height: 16px;
      width: 16px;
    }
  }
}

@include up($desktop-l) {
  .header {
    padding: 0 250px;
  }
}

@include up($desktop-hd) {
  .header {
    padding: 0 350px;
  }
}
