@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.navigation {
  background: $background_gray;
  flex-wrap: wrap;
  padding: 10px;
}

@include up($tablet) {
  .navigation {
    padding-block: 10px 0px;
    padding-inline: 50px;
    justify-content: space-between;
    min-height: 135px;
  }
}

@include up($desktop-s) {
  .navigation {
    padding-inline: 150px;
    min-height: 85px;
  }
}

@include up($desktop-l) {
  .navigation {
    padding-inline: 250px;
  }
}

@include up($desktop-hd) {
  .navigation {
    padding-inline: 350px;
  }
}
