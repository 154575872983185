@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.contact-us {
  width: 300px;
  font-family: "Inter", serif;
  text-align: justify;
  margin: 0 0 15px 0;
  font-size: 16px;
  line-height: 1.2;
}

.form-container {
  margin: 0;
  line-height: 2.5;

  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.form-message {
  border-radius: 4px;
  width: 300px;
  height: 100px;
  padding: 10px;
  border: none;
  background: $light-gray;
  margin-bottom: 20px;
}

.errors {
  font-size: 12px;
  color: $red;
  margin-top: -20px;
}

.form-button-container {
  width: min-content;
}

@media only screen and (min-width: $tablet) {
  .form-container {
    text-align: left;
  }
}
