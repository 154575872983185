@import "../../styles/globals.scss";

.upper-content {
  margin-bottom: 15px;
}

.lower-content {
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include up($tablet) {
  .upper-content {
    margin-bottom: 25px;
  }

  .lower-content {
    gap: 0;
    flex-direction: row;
    justify-content: space-around;
  }
}

@include up($desktop-s) {
  .upper-content {
    margin-bottom: 35px;
  }
}
