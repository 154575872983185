@import "../../styles/globals";

.container {
  display: none;
  flex-direction: column;
  position: relative;
  width: 220px;
  height: 240px;
  background: $white;
  border-radius: 20px;
  padding: 10px 5px 5px;

  &.active {
    display: flex;
  }

  .recommended-banner {
    position: absolute;
    top: -17px;
    right: -14px;

    img {
      height: 100px;
    }
  }

  .upper-content {
    .image {
      max-height: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  .lower-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .name {
      font-size: 16px;
      margin: 5px 0;
    }

    .description {
      margin: 0;
    }
  }
}

@include up($tablet) {
  .container {
    padding: 10px 5px 5px;
    width: 200px;
    height: 230px;

    .recommended-banner {
      top: -13px;
      right: -11px;

      img {
        height: 75px;
      }
    }

    .lower-content {
      .name {
        font-size: 18px;
      }

      .description {
        font-size: 16px;
      }
    }
  }
}

@include up($desktop-s) {
  .container {
    width: 280px;
    height: 290px;

    .recommended-banner {
      top: -19px;
      right: -16px;

      img {
        height: 100%;
      }
    }

    .upper-content {
      .image {
        max-height: 230px;
      }
    }
  }
}
