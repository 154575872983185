@import "./colors.scss";
@import "./breakpoints.scss";

// Spacing variables
$spacing1: 0.25rem;
$spacing2: 0.5rem;
$spacing3: 1rem;
$spacing4: 1.5rem;
$spacing5: 3rem;

* {
  transition: all 0.2s;
}

html {
  font-size: 14px;
  color: $text_gray;
}

h1 {
  font-size: 1.428rem; //20px
}

h2 {
  font-size: 1.285rem; // 18px
}

h3 {
  font-size: 1.142rem; // 16px
}

h4 {
  font-size: 1rem; // 14x
}

h5,
h6 {
  font-size: 1rem; //14px
}

@include up($desktop-s) {
  html {
    font-size: 16px;
  }

  h1 {
    font-size: 1.5rem; //24px
  }

  h2 {
    font-size: 1.375rem; // 22px
  }

  h3 {
    font-size: 1.25rem; // 20px
  }

  h4 {
    font-size: 1.125rem; // 18px
  }

  h5,
  h6 {
    font-size: 1rem; //16px
  }
}

body {
  margin: 0;
  background: $background_white;
  word-break: break-word;
  overflow: auto;
}

* {
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

label {
  display: inline-block;
}

button:not(:disabled) {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* flex utils */
.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.gap-0 {
  gap: 0 !important;
}

/* margins */

.m-0 {
  margin: 0;
}

.m-1 {
  margin: $spacing1;
}

.m-2 {
  margin: $spacing2;
}

.m-3 {
  margin: $spacing3;
}

.m-4 {
  margin: $spacing4;
}

.m-5 {
  margin: $spacing5;
}

.mb-1 {
  margin-bottom: $spacing1;
}

.mb-2 {
  margin-bottom: $spacing2;
}

.mb-3 {
  margin-bottom: $spacing3;
}

.mb-4 {
  margin-bottom: $spacing4;
}

.mb-5 {
  margin-bottom: $spacing5;
}

.mr-1 {
  margin-right: $spacing1;
}

.mr-2 {
  margin-right: $spacing2;
}

.mr-3 {
  margin-right: $spacing3;
}

.mr-4 {
  margin-right: $spacing4;
}

.mr-5 {
  margin-right: $spacing5;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: $spacing1;
}

.ml-2 {
  margin-left: $spacing2;
}

.ml-3 {
  margin-left: $spacing3;
}

.ml-4 {
  margin-left: $spacing4;
}

.ml-5 {
  margin-left: $spacing5;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: $spacing1;
}

.mt-2 {
  margin-top: $spacing2;
}

.mt-3 {
  margin-top: $spacing3;
}

.mt-4 {
  margin-top: $spacing4;
}

.mt-5 {
  margin-top: $spacing5;
}

.mx-1 {
  margin-left: $spacing1;
  margin-right: $spacing1;
}

.mx-2 {
  margin-left: $spacing2;
  margin-right: $spacing2;
}

.mx-3 {
  margin-left: $spacing3;
  margin-right: $spacing3;
}

.mx-4 {
  margin-left: $spacing4;
  margin-right: $spacing4;
}

.mx-5 {
  margin-left: $spacing5;
  margin-right: $spacing5;
}

.my-1 {
  margin-bottom: $spacing1;
  margin-top: $spacing1;
}

.my-2 {
  margin-bottom: $spacing2;
  margin-top: $spacing2;
}

.my-3 {
  margin-bottom: $spacing3;
  margin-top: $spacing3;
}

.my-4 {
  margin-bottom: $spacing4;
  margin-top: $spacing4;
}

.my-5 {
  margin-bottom: $spacing5;
  margin-top: $spacing5;
}

/* paddings */

.p-0 {
  padding: 0;
}

.p-1 {
  padding: $spacing1;
}

.p-2 {
  padding: $spacing2;
}

.p-3 {
  padding: $spacing3;
}

.p-4 {
  padding: $spacing4;
}

.p-5 {
  padding: $spacing5;
}

.pb-1 {
  padding-bottom: $spacing1;
}

.pb-2 {
  padding-bottom: $spacing2;
}

.pb-3 {
  padding-bottom: $spacing3;
}

.pb-4 {
  padding-bottom: $spacing4;
}

.pb-5 {
  padding-bottom: $spacing5;
}

.pe-1 {
  padding-right: $spacing1;
}

.pe-2 {
  padding-right: $spacing2;
}

.pe-3 {
  padding-right: $spacing3;
}

.pe-4 {
  padding-right: $spacing4;
}

.pe-5 {
  padding-right: $spacing5;
}

.ps-1 {
  padding-left: $spacing1;
}

.ps-2 {
  padding-left: $spacing2;
}

.ps-3 {
  padding-left: $spacing3;
}

.ps-4 {
  padding-left: $spacing4;
}

.ps-5 {
  padding-left: $spacing5;
}

.pt-1 {
  padding-top: $spacing1;
}

.pt-2 {
  padding-top: $spacing2;
}

.pt-3 {
  padding-top: $spacing3;
}

.pt-4 {
  padding-top: $spacing4;
}

.pt-5 {
  padding-top: $spacing5;
}

.px-1 {
  padding-left: $spacing1;
  padding-right: $spacing1;
}

.px-2 {
  padding-left: $spacing2;
  padding-right: $spacing2;
}

.px-3 {
  padding-left: $spacing3;
  padding-right: $spacing3;
}

.px-4 {
  padding-left: $spacing4;
  padding-right: $spacing4;
}

.px-5 {
  padding-left: $spacing5;
  padding-right: $spacing5;
}

.py-1 {
  padding-bottom: $spacing1;
  padding-top: $spacing1;
}

.py-2 {
  padding-bottom: $spacing2;
  padding-top: $spacing2;
}

.py-3 {
  padding-bottom: $spacing3;
  padding-top: $spacing3;
}

.py-4 {
  padding-bottom: $spacing4;
  padding-top: $spacing4;
}

.py-5 {
  padding-bottom: $spacing5;
  padding-top: $spacing5;
}

// Hidden and display

.hidden {
  display: none !important;
}

.hidden-mobile {
  display: none !important;
}

.show-mobile-only {
  display: initial;
}

@include up($tablet) {
  .hidden-mobile {
    display: inherit !important;
  }

  .show-mobile-only {
    display: none;
  }
}

.show {
  display: block !important;
}

// Links

.link {
  text-decoration: none;
  color: inherit;

  &.hover-color {
    &:hover {
      color: $primary_green_color;
    }
  }
}

.active {
  font-weight: bold !important;
}

// Width

.width-100 {
  width: 100%;
}

// Cookie consent

.cookie-consent-container {
  align-items: center !important;
  width: 95% !important;
  left: 10px !important;
  bottom: 20px !important;

  @include up($tablet) {
    width: 50% !important;
    left: 0 !important;
  }

  div:first-of-type {
    text-align: justify !important;
  }

  .cookie-consent-button {
    background: $button_green !important;
  }
}
