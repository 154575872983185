@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.search-bar {
  justify-content: center;
  margin-inline: 15px;
  margin-block: 10px;
  width: 100%;

  .form-search {
    display: flex;
    align-items: center;
    width: 95%;
    background: $background_white;
    border: 1px solid $search_bar_border;
    border-radius: 20px;

    button {
      border: 0;
      background: inherit;
      padding: 0;
      margin-left: 5px;
    }

    img {
      cursor: pointer;
      margin-left: 15px;
    }

    .input-search {
      width: 100%;
      border: 0;
      border-radius: inherit;
      outline: 0;
    }
  }
}

@include up($tablet) {
  .search-bar {
    width: initial;
    margin: 7px;

    .form-search {
      min-width: 420px;

      .input-search {
        display: initial;
      }
    }
  }
}

@include up($desktop-s) {
  .search-bar {
    .form-search {
      min-width: 450px;
    }
  }
}

@include up($desktop-m) {
  .search-bar {
    .form-search {
      min-width: 250px;
    }
  }
}

@include up($desktop-hd) {
  .search-bar {
    .form-search {
      min-width: 450px;
    }
  }
}
