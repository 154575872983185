@import "../../styles/colors.scss";

.back-to-top {
  display: none;
  color: $primary_green_color;
  font-size: 48px;
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;

  &.display {
    display: block;
  }

  svg {
    position: relative;
  }

  .background {
    height: 38px;
    width: 38px;
    background: $white;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    bottom: 16px;
  }
}
