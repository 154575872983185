@import "../../styles/breakpoints.scss";

.content {
  min-height: 700px;
}

@include up($tablet) {
  .content {
    min-height: 900px;
  }
}

@include up($desktop-s) {
  .content {
    min-height: 1000px;
  }
}
