@import "../../styles/colors.scss";

.btn {
  border-radius: 10px;
  cursor: pointer;
  border: none;
  min-width: 96px;

  &.btn-primary {
    color: $white;
    background-color: $button_green;
  }

  &.btn-bold {
    color: $white;
    background-color: $button_green;
    font-weight: bold;
  }

  &.btn-mobile {
    padding: 14px;
    font-size: 16px;
  }
}
