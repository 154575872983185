@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.container-slider {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 5px;
  background: $white;
  box-shadow: 4px 4px 20px rgba($black, 0.25);
  overflow: hidden;

  .heading {
    align-self: center;
    color: $primary_green_color;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .content {
    display: flex;
    height: 70px;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;

    .partner {
      display: none;
      padding: 0;
      height: inherit;

      img {
        height: auto;
        max-height: 50px;
        max-width: 80px;
      }

      &.active {
        display: flex;
        align-items: center;
      }
    }
  }

  .arrow {
    position: absolute;
    font-size: 20px;
    color: $primary_green_color;
    bottom: 5px;
    cursor: pointer;

    &.left-arrow {
      left: 45%;
    }

    &.right-arrow {
      right: 45%;
    }
  }
}

@include up($tablet) {
  .container-slider {
    padding: 20px;

    .content {
      height: 90px;

      .partner {
        padding: 10px;

        img {
          max-height: 100%;
          max-width: 170px;
        }
      }
    }

    .heading {
      font-size: 24px;
    }
  }
}

@include up($desktop-s) {
  .container-slider {
    .content {
      justify-content: space-around;

      .partner {
        img {
          max-width: 200px;
        }
      }
    }
  }
}

@include up($desktop-m) {
  .container-slider {
    padding: 20px 150px;

    .content {
      height: 100px;

      .partner {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
