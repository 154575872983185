@use "../../styles/colors";
@import "../../styles/globals.scss";

.featured-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  .heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
  }

  .categories {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}

@include up($tablet) {
  .featured-categories {
    justify-content: space-around;
    width: 100%;

    .categories {
      flex-direction: row;
      align-items: initial;
    }
  }
}

@include up($desktop-s) {
  .featured-categories {
    .heading {
      font-size: 20px;
    }
  }
}
