@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.footer {
  background: $background_footer;
  color: $white;
  font-size: 16px;

  .transform-180 {
    transform: rotate(180deg);
  }

  .footer-div {
    flex-direction: column;
    align-items: center;

    .column-div {
      width: 100%;
      border-bottom: solid 1px;

      h3 {
        font-size: 18px;
      }
    }

    a:hover {
      color: $primary_green_color;
    }

    address {
      line-height: 25px;
    }

    .facebook-link {
      .facebook-icon {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }

    .logo {
      width: 15%;
      margin: auto;
    }
  }
}

@include up($tablet) {
  .footer {
    .footer-div {
      flex-direction: row;
      align-items: initial;

      .column-div {
        width: initial;
        border: initial;

        div:first-child {
          padding-inline: initial;
        }
      }

      .facebook-link {
        justify-content: start;
      }

      .logo {
        width: 50%;
      }
    }
  }
}
