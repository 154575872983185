@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.no-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;

    a {
      color: $dodger-blue;
      text-decoration: none;
    }
  }

  img {
    max-width: 50%;
  }
}

@include up($tablet) {
  .no-products-container {
    p {
      margin-top: 10px;
      max-width: 50%;
    }

    img {
      max-width: 30%;
    }
  }
}
