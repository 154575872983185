//Basic colors
$black: #000000;
$gray: #808080;
$light-gray: #ededed;
$red: #ff0000;
$silver: #c0c0c0;
$transparent-overlay: rgba($black, 0.3);
$white: #ffffff;

//Primary colors
$primary_green_color: #008000;
$primary_gray: #8a8a8a;
$light-green: #86bb7d;
$extra-light-green: #86bb7d;
$light-gray: #d5d5d5;
$extra-light-gray: #eeeeee;
$dodger-blue: #1e90ff;

//Background colors
$background_light_green: #d9ecd9;
$background_gray: linear-gradient(180deg, #d7d7d7 0%, rgba(223, 223, 223, 0) 100%);
$background_linear_green: linear-gradient(
  180deg,
  rgba(0, 128, 0, 0.6) 0%,
  rgba(196, 105, 58, 0.03) 100%
);
$background_linear_green_secondary: linear-gradient(
  180deg,
  $light-green 0%,
  $extra-light-green 97.66%
);
$background_linear_gray_secondary: linear-gradient(
  180deg,
  $light-gray 0%,
  $extra-light-gray 97.66%
);
$background_white: $white;
$background_footer: rgba(177, 177, 177, 0.9);

//Button colors
$button_green: #349f6a;

//Text colors
$text_green: #008000;
$text_white: #ffffff;
$text_gray: #000000;

//Footer color
$footer_color: #8a8a8a;

//Search Bar color:
$search_bar_border: #c2c2c2;

//SubcategoriesMenu
$subcategories-menu-border: #e1e1e1;

//Icon colors
$icon_lighter_gray: rgba(219, 214, 214, 0.912);
