@import "../../styles/breakpoints.scss";

.search-results-main-container {
  margin-top: 30px;
}

.search-results-div {
  padding-inline: 10px;
}

.no-data {
  min-height: 500px;
  width: 100%;
  text-align: center;
}

@include up($tablet) {
  .search-results-div {
    padding-inline: initial;
    margin-left: 25px;
    width: 100%;
  }

  .no-data {
    margin-left: 25px;
  }
}
