@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.categories-menu-parent {
  position: fixed;
  width: fit-content;
  min-width: 300px;
  top: 149px;
  left: 0;
  bottom: 0;
  background-color: $transparent-overlay;
  z-index: 200;
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s, -webkit-transform 0.3s;

  .categories-menu {
    max-width: 300px;
    height: 100%;

    &.loading {
      min-height: 350px;
      background: $background_linear_green_secondary;
    }

    .category-header {
      background: $primary_green_color;
      color: $white;
      text-transform: uppercase;

      p {
        font-size: 18px;
      }
    }

    .category-body {
      background: $background_linear_green_secondary;
      position: relative;
      height: inherit;

      .category-ul {
        li {
          padding: 14px;
          list-style-type: none;
          font-size: 16px;

          &:hover {
            & > ul {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.transform-mobile {
  transform: translateX(-100%);
}

.overflow-y {
  overflow-y: auto;
}

.overflow-y-unset {
  overflow-y: unset;
}

@include up($tablet) {
  .categories-menu-parent {
    position: initial;
    background-color: initial;

    .categories-menu {
      max-width: 370px;

      .category-header {
        p {
          font-size: 20px;
        }
      }

      .category-body {
        background: $background_linear_green;
        overflow: unset;

        .category-ul {
          li {
            position: relative;

            &:hover {
              background-color: $white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .transform-mobile {
    transform: none;
  }
}

@include up($tablet) {
  .categories-menu-parent {
    min-height: 451px;
  }
}

@include up($desktop-s) {
  .categories-menu-parent {
    min-width: 350px;
    min-height: 425px;
  }
}
