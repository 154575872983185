@import "../../styles/globals.scss";

.container-slider {
  max-width: 100%;

  .heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
  }

  .products {
    display: flex;
    position: relative;
    gap: 10px;
    background: rgba(0, 128, 0, 0.15);
    border-radius: 15px;
    padding: 25px 35px;
  }

  .arrow {
    position: absolute;
    font-size: 20px;
    color: $white;
    top: 50%;
    cursor: pointer;

    stroke: $white;
    stroke-width: 1px;
  }

  .left-arrow {
    left: 8px;
  }

  .right-arrow {
    right: 8px;
  }
}

@include up($tablet) {
  .container-slider {
    .products {
      gap: 20px;
    }
  }
}

@include up($desktop-s) {
  .container-slider {
    .products {
      gap: 25px;
    }

    .heading {
      font-size: 20px;
    }
  }
}

@include up($desktop-hd) {
  .container-slider {
    .products {
      gap: 40px;
    }
  }
}
