@import "../../styles/colors.scss";

.select-subject {
  border-radius: 4px;
  width: 300px;
  padding: 10px;
  border: none;
  background: $light-gray;
  margin-bottom: 20px;
}
