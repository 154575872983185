@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

$dot_size: 15px;

.container-slider {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;

  &.product-slider {
    width: 200px;
    height: 200px;

    .slide {
      img {
        object-fit: contain;
      }
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &.active-anim {
    opacity: 1;
    z-index: -1;
  }
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  .dot {
    width: $dot_size;
    height: $dot_size;
    border-radius: 50%;
    border: 1px solid $black;
    margin: 0 5px;
    background: $white;
    cursor: pointer;

    &.active {
      background: $primary_green_color;
    }
  }
}

@include up($tablet) {
  .container-slider {
    width: 100%;
    height: auto;
    margin-left: 15px;

    &.product-slider {
      width: 250px;
      height: 250px;
      margin-left: 0;
    }
  }
}

@include up($desktop-s) {
  .container-slider {
    margin-left: 25px;
  }
}
