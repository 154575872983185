@import "../../styles/globals.scss";

.about-container {
  display: flex;
  flex-direction: column;

  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .about-title {
      text-align: center;
      color: $text_green;
      font-family: "Brawler", serif;
      font-size: 22px;
      margin: 0 0 10px;
    }

    .about-text {
      max-width: 350px;
      padding: 0;
      text-align: justify;
      line-height: 20px;
    }

    .about-button {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  .about-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include up($tablet) {
  .about-container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .about-content {
      .about-text {
        max-width: 400px;
        text-align: left;
        line-height: 24px;
      }
    }
  }
}

@include up($desktop-s) {
}

@media only screen and (min-width: $desktop-m) {
  .about-container {
    .about-content {
      .about-text {
        max-width: 450px;
      }
    }
  }
}

@media only screen and (min-width: $desktop-hd) {
  .about-container {
    .about-content {
      .about-text {
        max-width: 600px;
      }
    }
  }
}
