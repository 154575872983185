@import "../../styles/breakpoints.scss";

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 20px;
}

@include up($tablet) {
  .grid {
    gap: 10px 30px;
  }
}

@include up($desktop-s) {
  .grid {
  }
}

@include up($desktop-m) {
  .grid {
  }
}

@include up($desktop-l) {
  .grid {
  }
}

@include up($desktop-hd) {
  .grid {
    gap: 20px 50px;
  }
}
