@import "../../styles/breakpoints.scss";

.zone {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto 50px;
  justify-content: center;
  padding: 0 10px;

  &.orientation-column {
    flex-direction: column;
  }

  &.no-padding {
    padding: 0;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.max-height {
    max-height: 171px;
  }
}

@include up($tablet) {
  .zone {
    padding: 0 50px;
    margin-top: 30px;

    &.max-height {
      max-height: 456px;
    }
  }
}

@include up($desktop-s) {
  .zone {
    padding: 0 150px;

    &.max-height {
      max-height: 424px;
    }
  }
}

@include up($desktop-l) {
  .zone {
    padding: 0 250px;
  }
}

@include up($desktop-hd) {
  .zone {
    max-width: $desktop-hd;
    padding: 0 350px;
  }
}
