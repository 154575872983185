@import "../../styles/colors.scss";
@import "../../styles/globals.scss";

$border-radius: 12px;

.category-card-container {
  position: relative;
  width: 160px;
  height: 160px;
  margin-bottom: 20px;
  cursor: pointer;

  .category-card {
    position: relative;
    width: 100%;
    height: 100%;
    background: $background_footer;
    border-radius: $border-radius;
    filter: brightness(0.85);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;

    .heading {
      font-size: 16px;
      font-weight: 600;
      color: $white;
      text-align: center;
    }

    .button-container {
      display: none;
    }
  }
}

@include up($tablet) {
  .category-card-container {
    width: 220px;
    height: 220px;

    .text-container {
      padding-left: 15px;
      padding-right: 15px;

      .heading {
        font-size: 18px;
      }
    }
  }
}

@include up($desktop-s) {
  .category-card-container {
    width: 240px;
    height: 240px;

    &.show {
      cursor: unset;

      &:hover {
        .category-card {
          filter: brightness(0.65);
        }

        .text-container {
          padding-top: 20%;

          .button-container {
            display: block;
          }
        }
      }
    }
  }
}

@include up($desktop-hd) {
  .category-card-container {
    width: 260px;
    height: 260px;

    .text-container {
      padding-left: 20px;
      padding-right: 20px;

      .heading {
        font-size: 20px;
      }
    }
  }
}
