@import "../../styles/globals.scss";

.spinner-container {
  margin: auto auto;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  margin: auto;
  border: 10px solid $light-gray;
  border-top: 10px solid $primary_green_color;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
