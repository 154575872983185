@use "../../styles/colors";
@import "../../styles/globals.scss";

.paragraph {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
}
