//Breakpoints
$tablet: 768px;
$desktop-s: 1024px;
$desktop-m: 1200px;
$desktop-l: 1400px;
$desktop-hd: 1920px;

@mixin down($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin up($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}
