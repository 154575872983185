@import "../../styles/globals.scss";

.products-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .product-container {
      display: flex;
      flex-direction: column;
      width: 330px;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

      .top-content {
        display: flex;
        margin: 0 auto;
      }

      .bottom-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        .heading {
          font-size: 16px;
          justify-self: center;
          text-align: center;
          margin: 10px 0;
        }

        .description {
          text-align: left;
          padding: 0 10px;
          margin: 0;
        }

        .product-attributes {
          list-style-type: none;
          margin: 15px 0;
          padding: 0 10px;

          .attribute-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 3px;

            .label {
              text-transform: capitalize;
              min-width: fit-content;

              &:after {
                content: ":";
              }
            }

            .value {
              margin-left: 5px;
              text-align: left;
            }
          }
        }
      }

      .bold {
        font-weight: bold;
      }
    }
  }
}

@include up($tablet) {
  .products-container {
    .products-list {
      justify-content: space-between;

      .product-container {
        &:not(.search-page) {
          width: 48%;
        }
      }

      &.only-2 {
        justify-content: start;
      }

      &.only-1 {
        .product-container {
          width: 330px;
        }
      }
    }
  }
}

@include up($desktop-s) {
  .products-container {
    .products-list {
      .product-container {
        .bottom-content {
          .heading {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@include up($desktop-m) {
  .products-container {
    .products-list {
      &:not(.only-1) {
        .product-container {
          &.search-page {
            width: 47%;
          }
        }
      }
    }
  }
}

@include up($desktop-l) {
  .products-container {
    .products-list {
      gap: 30px;

      &:not(.only-1) {
        .product-container {
          &:not(.search-page) {
            width: 30%;
          }
        }
      }
    }
  }
}
