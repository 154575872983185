@import "../../styles/globals.scss";

.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  .flip-card-container {
    width: 300px;
    height: 100px;
    perspective: 800px;
    cursor: pointer;

    &.flipped {
      .flip-card-content {
        transform: rotateY(180deg);
      }
    }

    .flip-card-content {
      width: 100%;
      height: 100%;
      position: relative;

      background: $background_linear_gray_secondary;
      border-radius: 30px;

      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      -webkit-animation: flip 1s 1;
      animation: flip 1s 1;

      .card {
        height: 100%;
        width: 100%;
        backface-visibility: hidden;
        box-sizing: border-box;

        &.front-card {
          display: flex;
          justify-content: center;
          padding-top: 13%;

          p {
            font-size: 16px;
            font-weight: bold;
          }
        }

        &.back-card {
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          transform: rotateY(180deg);
          border-radius: 30px;
        }
      }
    }
  }
}

@include up($tablet) {
  .cards-container {
    flex-direction: row;

    .flip-card-container {
      .flip-card-content {
        .card {
          &.front-card {
            padding-top: 0;
            align-items: center;
          }
        }
      }
    }
  }
}

@include up($desktop-s) {
  .cards-container {
    gap: 50px;

    .flip-card-container {
      width: 400px;
      min-height: 150px;

      &:hover {
        .flip-card-content {
          transform: rotateY(180deg);
        }
      }

      .flip-card-content {
        border-radius: 10px;

        .card {
          &.front-card {
            p {
              font-size: 18px;
            }
          }

          &.back-card {
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@-webkit-keyframes flip {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes flip {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
