@import "../../styles/globals.scss";

.container {
  display: flex;
  align-items: center;

  .image-box {
    margin: 5px;
    display: flex;
    justify-content: center;

    .image-design {
      width: 150px;
      height: 150px;
      filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.25));
    }

    .rounded {
      border-radius: 50%;

      &.rounded-desktop {
        border-radius: 50%;
        width: 80%;
        height: auto;
      }
    }
  }
}

@include up($tablet) {
  .container {
    gap: 30px;

    &.display-column {
      flex-direction: column;
      justify-content: space-evenly;
    }

    .image-box {
      margin: 0;

      .rounded {
        border-radius: 10%;

        &.rounded-desktop {
          border-radius: 50%;
          width: 200px;
          height: auto;
        }
      }
    }
  }
}

@include up($desktop-s) {
  .container {
    width: 100%;
    justify-content: space-evenly;

    .image-box {
      justify-content: end;

      .rounded {
        border-radius: 0;
      }
    }
  }
}

@include up($desktop-m) {
  .container {
    .image-box {
      .image-design {
        width: 200px;
        height: 200px;
      }
    }
  }
}

@include up($desktop-hd) {
  .container {
    .image-box {
      .image-design {
        width: 250px;
        height: 250px;
      }
    }
  }
}
