@import "../../styles/globals";

.container {
  display: table;
  width: 100%;
  height: 50vh;
  text-align: center;

  .content {
    display: table-cell;
    vertical-align: middle;

    h1 {
      font-size: 30px;
      display: inline-block;
      padding-right: 12px;
      animation: type 0.5s alternate infinite;
    }

    p {
      padding: 20px;
      text-align: center;
    }

    a {
      color: $button_green;
      text-decoration: none;

      &:hover {
        color: $text_green;
      }
    }
  }
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
