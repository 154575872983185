@use "../../styles/colors";
@import "../../styles/globals.scss";

.container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  .icon-box-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    font-size: 18px;
  }

  .icons-container {
    display: flex;
    flex-direction: column;

    .text-icon-box {
      color: colors.$primary_gray;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text {
        margin-left: 20px;
        width: 250px;
        text-align: justify;

        &.bold {
          font-weight: 600;
        }
      }

      .icon {
        font-size: 38px;
        color: colors.$icon_lighter_gray;
      }
    }

    &.contact-page {
      flex-direction: column;

      .text-icon-box {
        flex-direction: column;
        margin: 10px 0;

        .text {
          text-align: center;
          margin: 5px;
          color: colors.$black;
        }

        .icon {
          margin: 5px 0;
          color: colors.$text_green;
        }
      }
    }
  }
}

@include up($tablet) {
  .container {
    .icon-box-title {
      font-size: 20px;
    }

    .icons-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .text-icon-box {
        font-size: 18px;

        .text {
          width: 300px;
        }

        .icon {
          margin-left: 20px;
          font-size: 45px;
        }
      }
    }
  }
}

@include up($desktop-s) {
  .container {
    .icon-box-title {
      font-size: 22px;
    }

    .icons-container {
      .text-icon-box {
        .text {
          width: 320px;
        }

        .icon {
          margin-left: 25px;
          font-size: 50px;
        }
      }
    }
  }
}
