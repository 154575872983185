@use "../../styles/colors";
@import "../../styles/globals.scss";

.header {
  display: flex;
  align-self: center;
  padding: 6px;
  flex-direction: column;
  border-bottom: 3px solid #008000;
  margin-bottom: 30px;
  font-size: 18px;
}

.h3 {
  margin-bottom: 5px;
}

.section {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

h4 {
  margin: 12px 0;
  font-size: 14px;
}

.entire-paragraph {
  text-align: justify;
}

p {
  margin: 5px 0;
  text-align: justify;
}

.site-name {
  text-decoration: none;
  color: colors.$button_green;

  &:hover {
    color: colors.$text_green;
  }

  &:active {
    color: colors.$text_green;
  }
}

@include up($tablet) {
  .header {
    font-size: 20px;
  }

  h4 {
    font-size: 14px;
  }
}

@include up($desktop-s) {
  .header {
    font-size: 22px;
  }

  h4 {
    font-size: 16px;
  }
}

@include up($desktop-l) {
  .header {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }
}

@include up($desktop-hd) {
  .header {
    font-size: 26px;
  }
}
