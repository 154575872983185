@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";

.right-menu {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 2px solid $gray;

  .link {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    padding: 10px;
  }
}

@include up($tablet) {
  .right-menu {
    border-top: none;

    .link {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
}

@include up($desktop-m) {
  .right-menu {
    width: inherit;
    justify-content: unset;

    .link {
      margin-bottom: 0;
    }
  }
}
