@import "../../styles/globals.scss";

.hero-image {
  position: relative;
  width: 100%;
  min-height: 146px;

  .image-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;

    &.no-image-filter {
      img {
        filter: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      filter: brightness(0.5);
    }
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 15px;
    transform: translate(0, -50%);
    color: $text_white;

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
      text-align: center;
    }
  }
}

@include up($tablet) {
  .hero-image {
    min-height: 278px;
    &:not(.only-hero-image) {
      margin-left: 25px;
      z-index: -1;
    }

    &.only-hero-image {
      max-height: 350px;
    }

    .image-container {
      border-radius: 2%;
      height: 100%;
    }

    .text-container {
      h1 {
        font-size: 24px;
      }
    }
  }
}

@include up($desktop-s) {
  .hero-image {
    &.only-hero-image {
      max-height: 500px;
    }

    .image-container {
      border-radius: 1%;
    }

    .text-container {
      h1 {
        font-size: 26px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

@include up($desktop-m) {
  .hero-image {
    min-height: 353px;
  }
}

@include up($desktop-l) {
  .hero-image {
    &.only-hero-image {
      min-height: 424px;
      max-height: 600px;
    }
  }
}

@include up($desktop-hd) {
  .hero-image {
    &.only-hero-image {
      max-height: 750px;
    }
  }
}
