@import "../../styles/colors.scss";

.form-input {
  border-radius: 4px;
  width: 300px;
  padding: 10px;
  border: none;
  background: $light-gray;
  margin-bottom: 20px;
}

label {
  margin-right: 10px;
  text-transform: capitalize;

  &:after {
    content: ":";
  }
}
