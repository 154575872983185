@import "../../styles/breakpoints.scss";

.logo-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 22px;

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 45px;
      max-width: 150px;
    }

    h1 {
      font-size: 18px;
    }
  }

  .logo-text-format {
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 9px;
    font-weight: 500;
    margin-top: -8px;
  }
}

@include up($tablet) {
  .logo-container {
    margin-right: initial;
    flex: initial;

    .logo {
      img {
        max-width: 200px;
      }

      h1 {
        font-size: 28px;
      }
    }

    .logo-text-format {
      letter-spacing: 2px;
    }

    h2 {
      font-size: 13px;
    }
  }
}

@include up($desktop-m) {
  .logo-container {
    .logo {
      img {
        height: auto;
      }
    }
  }
}
